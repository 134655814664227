import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree
} from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { AppTranslationService } from '../localization/localization.service';
import { environment } from 'src/environments/default/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard {
  constructor(private router: Router, private authenticationService: AuthenticationService, private tsvc: AppTranslationService, private jwtSvc: JwtHelperService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.authenticationService.currentUser?.token ?? "";
      //console.log("Autenticazione guardia: ", token, " - ", !this.jwtSvc.isTokenExpired(token))

      try {
        this.jwtSvc.decodeToken(token);

        if(!this.jwtSvc.isTokenExpired(token)){
          return true;
        }

      } catch (error) {
        //
      }

      if(environment.COMPANY_ABB.strEq("ke")){
        return this.router.navigate( [this.tsvc.currentLanguage.value, 'login'], {queryParams: route.queryParams})
      }

      return this.router.navigate( [this.tsvc.currentLanguage.value, 'register'], {queryParams: route.queryParams});
  }
}
