import { Injectable } from '@angular/core';

import { StorageService } from '../storage/storage.service';
import { IAuthenticatedUser } from './interfaces/iauthenticated-user';
import { lastValueFrom } from 'rxjs';
import { BookingService, CheckReferral, FeedbackPolicy, PurpleApiResponseStatus, UsersService } from '../sdk';
import { AppTranslationService } from '../localization/localization.service';
import { environment } from 'src/environments/default/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PurpleTranslationPipe } from 'src/purple-widgets/custom-pipe/translation.pipe';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { Helper } from 'src/purple-widgets/helpers/helper.extensions';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  isLoggedIn: boolean = false;
  currentUser: IAuthenticatedUser | undefined;
  storageMode: string = "local";

  constructor(private storageService: StorageService, private tsvc: AppTranslationService, private userSvc: UsersService, private mesSvc: NzMessageService, private jwtService: JwtHelperService,
    private modal: NzModalService, private tranPipe: PurpleTranslationPipe, private bookingSvc: BookingService, private seSvc: SaleEventService, private notSvc: NzNotificationService) {
    this.loadFromStorage();

  }

  async requestResetPassword(email: string, clientHost: string, isMobile: boolean): Promise<boolean> {
    const reqResp = await lastValueFrom(this.userSvc.requestResetPassword(this.tsvc.currentLanguage.value, {
      email: email,
      clientHost: clientHost
    }));

    const isOk = reqResp.status == PurpleApiResponseStatus.Success;
    var title = "";

    if (isOk) {
      if (isMobile) {
        title = this.tranPipe.transform("modal_request_reset_password_title_mobile_ok", "Richiesta inviata")
      } else {
        title = this.tranPipe.transform("modal_request_reset_password_title_ok", "Richiesta inviata correttamente")
      }
    } else {
      if (isMobile) {
        title = this.tranPipe.transform("modal_request_reset_password_title_mobile_ko", "Errore")
      } else {
        title = this.tranPipe.transform("modal_request_reset_password_title_ko", "Ops! qualcosa è andato storto")
      }
    }

    this.modal.create(
      {
        nzTitle: title,
        nzContent: reqResp.message,
        nzWidth: isMobile ? '80%' : '600px',
        nzClassName: 'ps-modal',
        nzCentered: isMobile ? true : false,
        nzClosable: false,
        nzMaskClosable: false,
        nzCancelText: null,
        nzOkText: this.tranPipe.transform("request_reset_password_modal_button", "Conferma")
      }
    )

    return isOk;
  }

  async checkResetPasswordLinkUrl(resetId: string): Promise<{ status: boolean, message: string }> {
    const reqResp = await lastValueFrom(this.userSvc.checkResetPasswordLink(this.tsvc.currentLanguage.value, {
      resetId: resetId
    }));

    return { status: (reqResp.status ?? PurpleApiResponseStatus.Error) == PurpleApiResponseStatus.Success, message: reqResp.message };
  }

  async resetPassword(email: string, resetId: string, newPassword: string): Promise<{ status: boolean, message: string }> {
    const reqResp = await lastValueFrom(this.userSvc.resetPassword(this.tsvc.currentLanguage.value, {
      email: email,
      newPassword: newPassword,
      resetId: resetId,
      clientHost: window.location.hostname
    }));

    return { status: (reqResp.status ?? PurpleApiResponseStatus.Error) == PurpleApiResponseStatus.Success, message: reqResp.message };
  }

  async changeEmail(newEmail: string, resetId: string): Promise<{ status: boolean, message: string }> {
    const reqResp = await lastValueFrom(this.userSvc.changeEmail(this.tsvc.currentLanguage.value, {
      newEmail: newEmail,
      resetId: resetId,
      clientHost: window.location.hostname
    }));

    return { status: (reqResp.status ?? PurpleApiResponseStatus.Error) == PurpleApiResponseStatus.Success, message: reqResp.message };
  }

  async requestChangeEmail(email: string, clientHost: string, isMobile: boolean): Promise<boolean> {
    const reqResp = await lastValueFrom(this.userSvc.requestChangeEmail(this.tsvc.currentLanguage.value, {
      email: email,
      newEmail: "", //TODO DA SISTEMARE COSI DA SEMPRE ERRORE
      clientHost: clientHost
    }));

    const isOk = reqResp.status == PurpleApiResponseStatus.Success;
    var title = "";

    if (isOk) {
      if (isMobile) {
        title = this.tranPipe.transform("modal_request_change_email_title_mobile_ok", "Richiesta inviata")
      } else {
        title = this.tranPipe.transform("modal_request_change_email_title_ok", "Richiesta inviata correttamente")
      }
    } else {
      if (isMobile) {
        title = this.tranPipe.transform("modal_request_change_email_title_mobile_ko", "Errore")
      } else {
        title = this.tranPipe.transform("modal_request_change_email_title_ko", "Ops! qualcosa è andato storto")
      }
    }

    this.modal.create(
      {
        nzTitle: title,
        nzContent: reqResp.message,
        nzWidth: isMobile ? '80%' : '600px',
        nzClassName: 'ps-modal',
        nzCentered: isMobile ? true : false,
        nzClosable: false,
        nzMaskClosable: false,
        nzCancelText: null,
        nzOkText: this.tranPipe.transform("request_change_email_modal_button", "Conferma")
      }
    )

    return isOk;
  }

  async checkChangeEmailLinkUrl(email: string | undefined, changeId: string | undefined): Promise<{ status: boolean, message: string }> {

    if (email == undefined) {
      return { status: false, message: this.tranPipe.transform("check_change_email_email_undefined", "Errore! Qualcosa è andato storto") };
    }

    if (changeId == undefined) {
      return { status: false, message: this.tranPipe.transform("check_change_email_changeId_undefined", "Errore! Qualcosa è andato storto") };
    }

    const reqResp = await lastValueFrom(this.userSvc.checkChangeEmailLink(this.tsvc.currentLanguage.value, {
      email: email,
      changeId: changeId
    }));

    return { status: (reqResp.status ?? PurpleApiResponseStatus.Error) == PurpleApiResponseStatus.Success, message: reqResp.message };
  }


  isAdminUser(): boolean {
    if (this.currentUser != undefined) {
      return this.currentUser.roles.findIndex(f => f.toLowerCase() == "admin") != -1;
    }

    return false;
  }

  async login(username: string, password: string, mode: string = 'local'): Promise<boolean> {
    this.isLoggedIn = false;
    this.storageMode = mode;


    const loginResp = await lastValueFrom(this.userSvc.login(this.tsvc.currentLanguage.value, {
      email: username,
      password: password
    }))

    if (loginResp) {
      if ((loginResp.status ?? PurpleApiResponseStatus.Error) === PurpleApiResponseStatus.Success) {

        this.currentUser = {
          token: loginResp?.data!.accessToken ?? '',
          user: loginResp?.data!.user!,
          roles: loginResp?.data!.roles!
        };

        this.storageService.set('StorageMode', this.storageMode, this.storageMode);
        this.storageService.set('CurrentUser', this.currentUser, this.storageMode);

        this.isLoggedIn = this.currentUser?.token != undefined;
      } else {
        this.notSvc.error(this.tranPipe.transform('erro_message_default_title', 'Errore', []), loginResp.message ?? this.tranPipe.transform("message_login_error", "Errore! Login non effettuato"),
          { nzDuration: environment.ERROR_NOTIFICATION_DURATION, nzPlacement: "top" });

      }
    }

    return this.isLoggedIn;
  }

  async register(firstname: string, lastname: string, email: string, gender: string, password: string, phone: string | undefined, phonePrefix: string | undefined, addressId: string, policies: FeedbackPolicy[],
    returnUrl: string | undefined, saleEventId: string | undefined, clientHost: string):
    Promise<registerResponse> {
    var regOk = true;
    const registerResp = await lastValueFrom(this.bookingSvc.registerBooking(this.tsvc.currentLanguage.value, {
      email: email,
      firstName: firstname,
      lastName: lastname,
      password: password,
      externalAddressId: addressId,
      phone: phone,
      phonePrefix: phonePrefix,
      returnUrl: returnUrl,
      saleEventId: saleEventId,
      clientHost: clientHost,
      gender: gender,
      policies: policies
    }))

    if (registerResp) {
      if ((registerResp.status ?? PurpleApiResponseStatus.Error) === PurpleApiResponseStatus.Success) {
        this.mesSvc.success(this.tranPipe.transform("message_register_success", "Registrazione effettuata con successo, verifica la tua mail"), {
          nzDuration: environment.MESSAGE_DURATION + 1500
        });
      } else {
        this.notSvc.error(this.tranPipe.transform('erro_message_default_title', 'Errore', []), registerResp.message ?? this.tranPipe.transform("message_register_error", "Errore! Registrazione non effettuata"),
          { nzDuration: environment.ERROR_NOTIFICATION_DURATION, nzPlacement: "top" });

        regOk = false;
      }
    }

    return { registerOk: regOk, returnUrl: registerResp.data ?? undefined };
  }

  logout(): boolean {
    //console.log("LOGOUT")
    this.isLoggedIn = false;
    this.currentUser = undefined;
    this.seSvc.currentSaleEvent.next(undefined);
    this.seSvc.currentSaleEventUtc.next(undefined);
    this.storageService.remove('CurrentUser', this.storageMode);
    this.storageService.remove('StorageMode', this.storageMode);
    this.storageMode = 'local';

    var ref: CheckReferral | undefined | null = Helper.storeNewObj(this.storageService.get("Referral", "session"));

    if (ref != undefined && ref != null) {
      ref.alreadyLoggedWithIncorrectDomain = false;

      this.storageService.remove("Referral", "session");
      this.storageService.set("Referral", ref, "session");
    }
    return !this.isLoggedIn;
  }

  private async loadFromStorage(): Promise<void> {
    this.storageMode = this.storageService.get<string>('StorageMode', this.storageMode);
    this.currentUser = this.storageService.get<IAuthenticatedUser>('CurrentUser', this.storageMode);

    const token = this.currentUser?.token ?? "";
    try {
      this.jwtService.decodeToken(token);

      if (!this.jwtService.isTokenExpired(token)) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }


    } catch (error) {
      this.isLoggedIn = false;
    }

    if (!this.isLoggedIn) {
      this.storageService.remove('CurrentUser');
    }
    //console.log("LOAD FROM STORAGE")
  }

}


export interface registerResponse {
  returnUrl: string | undefined;
  registerOk: boolean;
}
