import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, map, throttleTime } from 'rxjs/operators';

import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { AppTranslationService } from '../localization/localization.service';
import { PurpleLoaderService } from 'src/purple-widgets/custom-components/purple-loader/purple-loader.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor, OnDestroy {

  private throttleLogout = new Subject<any>();
  subs: Subscription = new Subscription();

  constructor(private authenticationService: AuthenticationService, private router: Router, private tsvc: AppTranslationService, private ls: PurpleLoaderService) {
    this.subs.add(this.throttleLogout.pipe(throttleTime(5000)).subscribe(async url => {

      this.authenticationService.logout();
      await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
      this.ls.removeAllRequest("general");
    }));
  }

  ngOnDestroy(): void {   
    this.subs.unsubscribe();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        //console.log("AUTH INTERCEPTOR: ", response)
        if (response.status === 0) {
          //this.throttleLogout.next(1); 
          return throwError(() => new Error('Connection not available. Please check your internet connection and try again.'));

        } else if (response.status === 401) {
          this.throttleLogout.next(1);
        }

        return throwError(() => new Error(response.error));
      }));
  }


}
