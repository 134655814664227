import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from 'src/environments/default/environment';
import '../src/purple-widgets/helpers/string.extensions';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { RewriteFrames } from '@sentry/integrations';
import { CountryModule } from './app/country/country.module';

if (environment.production) {
  enableProdMode();
}

const urlParams = new URLSearchParams(window.location.search);
var hasCountry = false;

if (urlParams.has('country')) {
  hasCountry = true;
  const c = urlParams.get('country');
  const country = environment.COUNTRY_SELECT.find(f => f.COUNTRY_FLAG.strEq(c!))

  if (country != null && country != undefined) {
    localStorage.setItem("BasePath", country.COUNTRY_BASE_PATH);
    localStorage.setItem("DefaultLanguage", country.COUNTRY_DEFAULT_LANGUAGE);
  }else{
    hasCountry = false;
  }

  urlParams.delete('country');
  const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + urlParams.toString();
  window.history.replaceState({}, '', newUrl);
}

const basePath = localStorage.getItem("BasePath");
const DefaultLanguage = localStorage.getItem("DefaultLanguage");

if (!environment.SHOW_COUNTRY_SELECT || (basePath != undefined && basePath != null && DefaultLanguage != undefined && DefaultLanguage != null)) {

  if (!environment.SHOW_COUNTRY_SELECT && !hasCountry) {
    localStorage.setItem("BasePath", environment.COUNTRY_SELECT[0].COUNTRY_BASE_PATH);
    localStorage.setItem("DefaultLanguage", environment.COUNTRY_SELECT[0].COUNTRY_DEFAULT_LANGUAGE);
  }

  if (environment.production) {
    Sentry.init({
      dsn: "https://0d6ee6932f3c4c5a9960fecb659d7591@o440503.ingest.sentry.io/5469301",
      environment: environment.production ? "production" : "development",
      release: environment.appName + "@" + environment.appVersion,
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new Integrations.BrowserTracing({
          tracingOrigins: [localStorage.getItem("BasePath")!],
          routingInstrumentation: Sentry.routingInstrumentation,

        }),
        new RewriteFrames()
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      beforeSend(event, hint) {
        const error : any = hint.originalException;
        if (error && error.message && error.message.includes('Connection not available')) {
          return null;
        }
        return event;
      }
    });
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
      const icon1 = document.createElement('link');
      const icon2 = document.createElement('link');
      const icon3 = document.createElement('link');
      const manifest = document.createElement('link');
      const icon4 = document.createElement('link');

      const title = document.createElement('title');

      const safeCompanyAbb = String.prototype.isNullOrEmpty(environment.COMPANY_ABB) ? "default" : environment.COMPANY_ABB;
      const safeCompanyName = String.prototype.isNullOrEmpty(environment.COMPANY_NAME) ? "PurpleSales" : environment.COMPANY_NAME;


      title.innerText = safeCompanyName + " - Booking"

      document.head.appendChild(title)

      const meta1 = document.createElement('meta');
      meta1.name = "msapplication-TileColor";
      meta1.content = String.prototype.isNullOrEmpty(environment.HTML_TITLE_COLOR) ? "#000000" : environment.HTML_TITLE_COLOR;
      document.head.appendChild(meta1)

      const meta2 = document.createElement('meta');
      meta2.name = "theme-color";
      meta2.content = String.prototype.isNullOrEmpty(environment.HTML_THEME_COLOR) ? "#000000" : environment.HTML_THEME_COLOR;
      document.head.appendChild(meta2)


      if (environment.USE_IUBENDA) {
        const iubenda = document.createElement('script');
        iubenda.type = 'text/javascript';
        iubenda.innerHTML = environment.IUBENDA_COOKIE_POLICY;
        document.head.appendChild(iubenda)

        const iubenda1 = document.createElement('script');
        iubenda1.type = 'text/javascript';
        iubenda1.src = '//cdn.iubenda.com/cs/ccpa/stub.js';
        document.head.appendChild(iubenda1)

        const iubenda2 = document.createElement('script');
        iubenda2.type = 'text/javascript';
        iubenda2.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
        iubenda2.charset = 'UTF-8';
        iubenda2.async = true;
        document.head.appendChild(iubenda2)

        const iubenda3 = document.createElement('script');
        iubenda3.type = 'text/javascript';
        iubenda3.src = 'https://cdn.iubenda.com/cons/iubenda_cons.js';
        iubenda3.async = true;
        document.head.appendChild(iubenda3)

        const iubenda4 = document.createElement('script');
        iubenda4.type = 'text/javascript';
        iubenda4.innerHTML = environment.IUBENDA_CONS;
        document.head.appendChild(iubenda4)

        const iubenda5 = document.createElement('script');
        iubenda5.type = 'text/javascript';
        iubenda5.innerHTML = environment.IUBENDA_DEFAULT_HTML;
        document.head.appendChild(iubenda5)
      }

      fetch("/assets/icons/" + safeCompanyAbb + "/manifest.webmanifest")
        .then(
          () => {

            icon1.rel = "apple-touch-icon";
            icon1.setAttribute("sizes", "180x180")
            icon1.href = "/assets/icons/" + safeCompanyAbb + "/apple-touch-icon.png"

            icon2.rel = "icon";
            icon2.type = "image/png";
            icon2.setAttribute("sizes", "32x32")
            icon2.href = "/assets/icons/" + safeCompanyAbb + "/favicon-32x32.png"

            icon3.rel = "icon";
            icon3.type = "image/png";
            icon3.setAttribute("sizes", "16x16")
            icon3.href = "/assets/icons/" + safeCompanyAbb + "/favicon-16x16.png"

            manifest.href = "/assets/icons/" + safeCompanyAbb + "/manifest.webmanifest"

            icon4.rel = "mask-icon"
            icon4.href = "/assets/icons/" + safeCompanyAbb + "/safari-pinned-tab.svg"
            icon4.setAttribute("color", String.prototype.isNullOrEmpty(environment.SAFARI_COLOR) ? "#000000" : environment.SAFARI_COLOR)

            document.head.appendChild(icon1)
            document.head.appendChild(icon2)
            document.head.appendChild(icon3)
            document.head.appendChild(icon4)
            document.head.appendChild(manifest)
          }
        )
        .catch(
          () => {
            icon1.rel = "apple-touch-icon";
            icon1.setAttribute("sizes", "180x180")
            icon1.href = "/assets/icons/default/apple-touch-icon.png"

            icon2.rel = "icon";
            icon2.type = "image/png";
            icon2.setAttribute("sizes", "32x32")
            icon2.href = "/assets/icons/default/favicon-32x32.png"

            icon3.rel = "icon";
            icon3.type = "image/png";
            icon3.setAttribute("sizes", "16x16")
            icon3.href = "/assets/icons/default/favicon-16x16.png"

            manifest.href = "/assets/icons/default/manifest.webmanifest"

            icon4.rel = "mask-icon"
            icon4.href = "/assets/icons/default/safari-pinned-tab.svg"
            icon4.setAttribute("color", "#000000")

            document.head.appendChild(icon1)
            document.head.appendChild(icon2)
            document.head.appendChild(icon3)
            document.head.appendChild(icon4)
            document.head.appendChild(manifest)
          }
        )
    })
    .catch(err => console.error(err));
} else {
  platformBrowserDynamic()
    .bootstrapModule(CountryModule)
    .catch(err => console.error(err));
}




